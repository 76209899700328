<template>
	<div>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h1 class="text-center pt10">
							<strong>Panel de Administración</strong>
						</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {},
	data() {
		return {}
	},
	mounted() {},
}
</script>